import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import LinkBlock from '../../components/LinkBlock/linkBlock';
import SEO from '../../components/SEO/seo';

const InRainbowsAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/tools" noheader notopmargin >
        <SEO lang="ru" title={'Font Tester'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'In Rainbows', 'Radiohead']} description="Почему In Rainbows считается одним из лучших альбомов Radiohead. Как я полюбил Radiohead и начал понимать их ценность.">
            <link rel="alternate" href={`${site.siteMetadata.siteUrl}/tools/fedro`} hrefLang="en" key="en"/>
        </SEO>
        <HeroWithPhotoAndHeader blHeader backto="/tools" desc="Improve your UI Design efficiency with Free Figma File Covers" imageURL={'telegramTasks.png'} heading="Font Checker"/>

        <Section heading="Source">
            <LinkBlock href="https://www.figma.com/community/file/959057803056977489" heading="The Font Checker" para="Figma Community"/>
        </Section>
        <Section heading="Idea">
            <Paragraph>Find the right font it's often hard. Especially in UI design. I create this template to make your life a little easier, you can compare fonts by how they will be looking in the UI design and simple layouts.</Paragraph>
        </Section>

    </Layout>
  ); };

export default InRainbowsAlbumPage;
